import gsap from 'gsap'
import klaviyo from '../helpers/klaviyo'

class NewsletterForm {
    constructor(el) {
        this.container = el
        this.klaviyoForm()


    }

    setAnimations(els) {
        els.forEach((el) => {

            el.classList.remove('hidden')
            gsap.set(el, {
                autoAlpha: 0
            })
        })
    }

    async klaviyoForm() {
        const newsletterEls = document.querySelectorAll('[data-newsletter-form]')

        const data = {}

        newsletterEls.forEach(async (formWrapper) => {
            const formEl = formWrapper.querySelector('form')
            const successMessageEl = formWrapper.querySelector('[data-newsletter-success]')
            const errorMessageEl = formWrapper.querySelector('[data-newsletter-error]')
            const listId = formEl.getAttribute('data-list-id')
            const emailEL = formEl.querySelector('[data-newsletter-email]')
            const inputsEls = formEl.querySelectorAll('input')
            const submitBtn = formEl.querySelector('.newsletter__submit')
            const titleEl = formWrapper.parentElement.querySelector('h3')

            this.setAnimations([successMessageEl, errorMessageEl])

            formWrapper.addEventListener('submit', async (e) => {

                e.preventDefault()

                inputsEls.forEach((input) => {

                    data[input.name] = input.value
                })

                if (!data.email) {
                    errorMessageEl.innerText = 'Please enter an email'
                    gsap.to(successMessageEl, {
                        autoAlpha: 0
                    })
                    gsap.to(errorMessageEl, {
                        autoAlpha: 1
                    })
                    return
                }

                if (!klaviyo.validateEmail(data.email)) {
                    errorMessageEl.innerText = 'Please enter a valid email'
                    gsap.to(successMessageEl, {
                        autoAlpha: 0
                    })
                    gsap.to(errorMessageEl, {
                        autoAlpha: 1
                    })
                    return
                }

                // 
                const response = await klaviyo.subscribe(data.email, data.firstName, data.lastName, data.tag, listId)

                if (response.errors) {
                    emailEL.value = ''

                    gsap.to(errorMessageEl, {
                        autoAlpha: 0
                    })
                    gsap.to(successMessageEl, {
                        autoAlpha: 1
                    })

                    titleEl.innerText = 'Welcome to the Pinhook Family'

                    const inputs = formEl.querySelectorAll('input')
                    inputs.forEach(input => input.value = '')

                    gsap.to([inputs, submitBtn], {
                        autoAlpha: 0
                    })
                }
            })
        })
    }
}

const newsletterInit = {
    init() {
        const klaviyoElements = [...document.querySelectorAll('[data-newsletter-container]')]
        if (klaviyoElements.length > 0) {
            klaviyoElements.forEach(el => {
                new NewsletterForm(el)
            })
        }
    }
}

export default newsletterInit
